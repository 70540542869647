import React from "react";

// reactstrap components
import {Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row} from "reactstrap";

import {apiRequest, apiRoot} from 'lib/Api';
import {getAuthToken, getUserData} from 'lib/Auth';
import * as queryString from "query-string";
import * as moment from "moment";
import defaultAvatar from "assets/img/default-avatar.png";
import PerfectScrollbar from "perfect-scrollbar";
import {JitsiMeeting} from "@jitsi/react-sdk";
import { withUserAgent } from "react-useragent";

const PING_INTERVAL = 30000;  // 1 min

// for testing
const testMode = false;
let myUsername = null;

let lastMessage = '';
class Chat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            direct: '',
            username: '',
            message: '',
            messages: [],
            intervalId: null,
            isGuest: false,
            token: '',
            chatId: '',
            lastTime: '',
            lastId: '',
            currentUser: getUserData(),
            chatSessionId: null
        };


    }

    get id() {
        if (this.props.match.params) {
            return this.props.match.params.id;
        }
        return null;
    }

    get chatId() {
        return this.state.chatId
    }

    async componentDidMount() {
        if (!this.id) {
            return;
        }

        let intervalId = setInterval(this.getMessages, 2000);
        this.setState({intervalId: intervalId});

        let token = queryString.parse(this.props.location.search).token;
        this.setState({token: token});
        await this.fetchChatInfo();

        window.addEventListener("unload", e => {

            // when page's unload, use sendBeacon to make sure that
            // a request will be send to backend
            const url = apiRoot + `/chat-sessions/close?token=` + getAuthToken() + `&chat_id=${this.chatId}`;
            const success = navigator.sendBeacon(url);
        })
    }

    handleChange = (name, value) => {
        if (typeof name == 'object' && typeof name.target == 'object') {
            let event = name;
            event.preventDefault();
            name = event.target.name;
            value = event.target.value;
        }
        this.setState({[name]: value});
    }



    /**
     * Fetch info required to open chat in rocket.chat
     */
    async fetchChatInfo() {

        let token = queryString.parse(this.props.location.search).token;
        if (token) {
            await apiRequest("/auth/guest?token=" + token, {method: "GET"}).then(response => {
                this.setState({isGuest: true})
                return;
            });
        } else {
            //return;
            return await apiRequest("/appointments/" + this.id + "/chat", {method: "POST"})
                .then(async response => {
                    this.setState({
                        direct: response.data.direct,
                        username: response.data.username
                    });
                    if (testMode) {
                        myUsername = response.data.username;
                    }
                    await this.openChatSession(this.id);
                    //await this.getMessages();
                });
        }
    }

    openChatSession = (roomId) => {
        if (this.state.isGuest) {
            return;
        }
        let params = {appointment_id: roomId, current_user: this.state.direct};
        return apiRequest('/chat-sessions/open', {method: "POST", data: params})
            .then(response => {
                let {data} = response.data;
                this.setState({chatId: data.id, chatSessionId: data.chat_id});
                setInterval(this.ping, PING_INTERVAL);
            });
    }

    maybeNotifyMessage = (message) => {
        let data = {message, chat_id: this.state.chatId};
        return apiRequest(`/chat-sessions/new-message`, {method: "POST", data});
    }

    ping = () => {
        if (this.state.isGuest) {
            return;
        }
        let data = {chat_id: this.state.chatId};
        return apiRequest(`/chat-sessions/ping`, {method: "POST", data});
    }

    componentDidUpdate() {
        if (!this.state.lastId) {
            return
        }
        if (!document.getElementById(this.state.lastId)) {
            return;
        }
        document.getElementById(this.state.lastId).scrollIntoView()
/*
        const apiUsers = new JitsiMeetExternalAPI("8x8.vc", {
            roomName: `vpaas-magic-cookie-3ada3419c51a496884fbaafaeb69f6fc/,
            parentNode: document.querySelector('#video-container-users'),
            // Make sure to include a JWT if you intend to record,
            // make outbound calls or use any other premium features!
            // jwt: "eyJraWQiOiJ2cGFhcy1tYWdpYy1jb29raWUtM2FkYTM0MTljNTFhNDk2ODg0ZmJhYWZhZWI2OWY2ZmMvNWYwNjg4LVNBTVBMRV9BUFAiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiJqaXRzaSIsImlzcyI6ImNoYXQiLCJpYXQiOjE2ODQ3ODM2NjgsImV4cCI6MTY4NDc5MDg2OCwibmJmIjoxNjg0NzgzNjYzLCJzdWIiOiJ2cGFhcy1tYWdpYy1jb29raWUtM2FkYTM0MTljNTFhNDk2ODg0ZmJhYWZhZWI2OWY2ZmMiLCJjb250ZXh0Ijp7ImZlYXR1cmVzIjp7ImxpdmVzdHJlYW1pbmciOmZhbHNlLCJvdXRib3VuZC1jYWxsIjpmYWxzZSwic2lwLW91dGJvdW5kLWNhbGwiOmZhbHNlLCJ0cmFuc2NyaXB0aW9uIjpmYWxzZSwicmVjb3JkaW5nIjpmYWxzZX0sInVzZXIiOnsiaGlkZGVuLWZyb20tcmVjb3JkZXIiOmZhbHNlLCJtb2RlcmF0b3IiOnRydWUsIm5hbWUiOiJUZXN0IFVzZXIiLCJpZCI6ImF1dGgwfDY0NmJjMWMxMjcwZGUzZmJhMjFiOTFlNSIsImF2YXRhciI6IiIsImVtYWlsIjoidGVzdC51c2VyQGNvbXBhbnkuY29tIn19LCJyb29tIjoiKiJ9.dBiz2bsReaFqOMUiXdpUYWZhzqy370azi_ZCm9EX6P3zsYZqHG_EDWHs-8mAjBI2awRV2swroQTBrAkAoXy-KCBUGnTf3drdO2R6AV_DAYfNFoku9X1jEsrL41TXUtFzyvqYqJoZjcG4PveRt23Kcwzwh6tem0BSkMiafueDakTlayUdL9Rxm6rW9WYoxpHpbCxTEClz7JQjpPAs5VAuD0jwbEwuqKnycegLq4n_PymUw_PZHKm2nUjDzxKi-A4Tyg3m-XeR9ZgfaMsKJz4mzYyVIu0j8Tty0z6xn6lVZggrDiNkUOdzmnCXEEnzr6G_nFvV-EM1lJ00JTCvwPTTpA"
        });
 */
    }

    send = async () => {
        let message = this.state.message
        if (this.state.message === '' || lastMessage === message) {
            return;
        }

        let data = {
            message: message,
            chat_id: this.state.chatId,
        }
        lastMessage = message;
        this.setState({message: ''});
        await apiRequest(`/chat/messages`, {method: "POST", data});
    }

    getMessages = async () => {
        let data = {
            chat_id: this.state.chatSessionId,
            last_check: this.state.lastTime
        }
        await apiRequest(`/chat/messages`, {method: "GET", data}).then((response) => {
            let messagesState = this.state.messages;
            const messages = response.data.data
            let lastId = ''

            if (messages.length > 0) {
                messagesState = messagesState.concat(messages)
                const lastMessage = messages[messages.length - 1];
                lastId = 'msg' + lastMessage.uuid;
                let lastTime = lastMessage.created_at;
                this.setState({messages: messagesState, lastTime: lastTime});
            }
            this.setState({lastId: lastId});
        });
    }

    renderMessages = () => {
        let messages = this.state.messages;
        return (
            messages.map((message, i) => {
                let className = "msg right-msg";
                if (message.user_id === this.state.currentUser.id) {
                    className = "msg left-msg";
                }

                let lastId = 'msg' + message.uuid;
                let hour = moment(message.created_at).format('DD/MM/YYYY - HH:mm')
                let avatar = message.picture_url || defaultAvatar;
                return (
                    <div className={className} key={message.uuid} id={lastId}>
                        <div
                            className="msg-img"
                        >
                            <img className={"image"} src={avatar}/>
                        </div>

                        <div className="msg-bubble">
                            <div className="msg-info">
                                <div className="msg-info-name">{message.name}</div>
                                <div className="msg-info-time">{hour}</div>
                            </div>

                            <div className="msg-text">
                                {message.message}
                            </div>
                        </div>
                    </div>
                )
            })
        )
    }

    componentWillUnmount() {
        // use intervalId from the state to clear the interval
        clearInterval(this.state.intervalId);
    }


    render() {
        let link = 'https://meet.jit.si/appointment_chat_' + this.id;
        if (!this.state.direct && false) {
            return <p></p>;
        }

        console.log('mobile -> ' + this.props.ua.mobile)

        if (this.state.isGuest) {
            return (
                <>
                    <div className="content">
                        <Row>
                            <Col>
                                {this.renderJitsi()}
                            </Col>
                        </Row>
                    </div>
                </>
            );
        } else {

            if (this.props.ua.mobile === null) {
                return (
                    <>
                        <div className="content">
                            <Row>
                                <Col md="5">
                                    <Row>
                                        <div className="chatbox" style={{height: '80vh'}}>
                                            <div className="msger-chat">
                                                {this.renderMessages()}
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="msger-inputarea">
                                            <FormGroup>
                                                <InputGroup>
                                                    <Input type='text' name='message' className='msger-input'
                                                           onKeyPress={event => {
                                                               if (event.which === 13) {
                                                                   event.preventDefault();
                                                                   this.send(event);
                                                               }
                                                           }
                                                           }
                                                           onChange={this.handleChange} value={this.state.message}
                                                    />
                                                    <InputGroupAddon addonType="append">
                                                        <InputGroupText onClick={this.send}
                                                                        className={'msger-send-btn'}>
                                                            &nbsp;<i className="fa fa-paper-plane"/>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </FormGroup>
                                        </div>
                                    </Row>
                                </Col>
                                {this.renderJitsiWeb()}
                            </Row>
                        </div>
                    </>
                )
            } else {
                return (
                    <>
                        <Row>
                            <Col md="5">
                                <Row>
                                    <div className="chatbox" style={{height: '40vh'}}>
                                        <div className="msger-chat">
                                            {this.renderMessages()}
                                        </div>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="msger-inputarea">
                                        <FormGroup>
                                            <InputGroup>
                                                <Input type='text' name='message' className='msger-input'
                                                       onKeyPress={event => {
                                                           if (event.which === 13) {
                                                               event.preventDefault();
                                                               this.send(event);
                                                           }
                                                       }
                                                       }
                                                       onChange={this.handleChange} value={this.state.message}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText onClick={this.send}
                                                                    className={'msger-send-btn'}>
                                                        &nbsp;<i className="fa fa-paper-plane"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                        {this.renderJitsiMobile()}
                    </>
                )
            }
        }
    }

    renderJitsiWeb() {

        if (this.props.ua.mobile === null) {
            return <>
                <Col md="6" id='video-container-users'>
                    {this.renderJitsi()}
                </Col>
            </>
        }
    }

    renderJitsiMobile() {
        if (this.props.ua.mobile !== null) {
            return <>
                <Row style={{marginTop: '50px', height: '770px'}}>
                    <Col>
                        {this.renderJitsi()}
                    </Col>
                </Row>
            </>
        }
    }

    renderJitsi() {

        let height = '98%';
        if (this.props.ua.mobile !== null) {
            height = '750px';
        }

        return <>
            <JitsiMeeting
                domain={"meet.selfguru.com.br"}
                roomName={`Encontro_${this.id}`}

                configOverwrite = {{
                    disableThirdPartyRequests: false,
                    disableLocalVideoFlip: true,
                    disableChat:true,
                    disablePolls: true,
                    lang:'pt',
                }}
                interfaceConfigOverwrite = {{
                    MOBILE_APP_PROMO: false,
                    TILE_VIEW_MAX_COLUMNS: 4,
                }}
                userInfo = {{
                    displayName: this.state.currentUser.name || ''
                }}
                getIFrameRef = { (iframeRef) => { iframeRef.style.height = height; } }
            />
        </>
    }
}

export default withUserAgent(Chat);
